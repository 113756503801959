import {
    ArticleCard,
    CarouselSimilar,
    CompareButton,
    DealerName,
    Heading,
    HorizontalDrawer,
    PaymentOptions,
    PromotionCard,
    RecentViews,
    StickyBar,
    StickyPaymentOptions,
    Trade360Widget,
    VdpBreadcrumb,
    VehicleCertified,
    VehicleCtas,
    VehicleHistory,
    VehicleName,
    VehicleRebatesList,
    VehicleSlider,
    VehicleSpecsExcerpt,
    VehicleTagline,
} from '@sm360/components'
import { QuoteModel, VehicleModel, WindowProxy, heightCalculator, resetParentsOverflow, setInnerHTML } from '@sm360/helpers'
import { useDeviceType, useTranslation } from '@sm360/hooks'
import React from 'react'

import { saveQuote, getCalculatorApiUrl } from '../../apis/showroom360/showroom360.service'
import { VdpContext } from '../../configs/vdp-view/state.manager'

import PageSections from '../page-sections/PageSections'

const VdpAlpha = () => {
    const baseClass = 's360-p-inventoryVdp'
    const {
        state: {
            vehicle,
            dealerInfos,
            dealerInfos: { domain, orgId, orgUnitId, lang, orgUnitMakes },
            similarVehicles,
            hubName,
            vehiclePromo,
            showroom360ConfigVdp,
            showroom360ConfigVdp: { usedVDPConfig, newVDPConfig, calculatorDisplay },
            quoteId,
            calculatorWidget,
            articleVehicle,
            tradeInWidget: { tradeInAtTheTop, tradeInBelowPicture, tradeInBelowCTA, tradeInAtTheBottom },
            paymentOptions: showroomPaymentOptions,
            XMSConfig: { pictureConfiguration, taggingConfigurations, tripleMathBoxConfiguration },
            XMSConfig,
        },
    } = React.useContext(VdpContext)

    const {
        newVehicle,
        make,
        model,
        year,
        trim,
        certified,
        odometer,
        fuel,
        pastOwner,
        severelyDamagedVehicle,
        repairHistory,
        futureReparations,
        previousUse,
        paymentOptions,
        multimedia,
        pageViewSummary,
        tagline,
        primaryOrganizationUnit,
        vehicleId,
        batteryCapacity,
        batteryRange,
        driveTrainDetails,
    } = vehicle

    const { displayTotalCreditCost, priceRounding } = tripleMathBoxConfiguration

    const recentViewData = taggingConfigurations?.find((config) => config.code === 'recent_view_count')

    const { t } = useTranslation()

    const { device } = useDeviceType()

    const [isCalculatorModalOpen, setIsCalculatorModalOpen] = React.useState(false)
    const [forceUpdatePurchaseMethod, setPurchaseMethod] = React.useState(false)

    // TODO: Make a configuration in Delta for displaying similar vehicles on VDP
    const displayCarouselSimilar = true

    // TODO: Remove this const once get data as props from webauto or From Delta
    const isMultiDealerInventory = false

    React.useEffect(() => {
        if (WindowProxy) {
            WindowProxy.document.addEventListener('sm360.calculator.vdp.modal.close', () => onCalculatorModalOpen(false))
        }
    }, [])

    React.useEffect(() => {
        if (document) {
            resetParentsOverflow('[data-s360-sticky]')
        }
    })

    const onCalculatorModalOpen = (isOpen) => {
        setIsCalculatorModalOpen(isOpen)
        setPurchaseMethod(true)
    }

    const handleCalculatorCta = async (purchaseMethod, term, frequency, kmPerYearPlan) => {
        const calculatorWidget = await getCalculatorApiUrl(
            lang,
            vehicleId,
            dealerInfos,
            quoteId,
            true,
            true,
            domain,
            purchaseMethod,
            term,
            frequency,
            kmPerYearPlan,
            calculatorDisplay
        )
        const dataStandalone = document.querySelectorAll('[data-standalone]')
        if (dataStandalone.length > 0) {
            dataStandalone.forEach(async (element) => {
                await setInnerHTML(element, calculatorWidget)
                setPurchaseMethod(false)
                setTimeout(() => setIsCalculatorModalOpen(true), 500)
            })
        }
    }

    const newCtas = showroom360ConfigVdp?.detailNewCTAConfigurations?.filter(
        ({ active, statusName }) => active && statusName === 'new'
    )
    const usedCtas = showroom360ConfigVdp?.detailUsedCTAConfigurations?.filter(
        ({ active, statusName }) => active && statusName === 'used'
    )

    const currentVDPConfig = newVehicle ? newVDPConfig : usedVDPConfig
    const { certifiedAboveCTA, certifiedBelowCTA, historyAboveCTA, historyBelowCTA } = currentVDPConfig || {}

    /**
     * Create public quote on similar model tile click and redirect
     */
    const handleSimilarModelTileClick = async (vehicleId, categoryName) => {
        const vehicleModel = vehicle instanceof VehicleModel ? vehicle : new VehicleModel(vehicle)

        const quote = new QuoteModel().set('vehicle', vehicleModel)
        const config = {
            orgId,
            orgUnitId,
        }
        const quoteId = await saveQuote(quote.getShowroomQuote(), config, lang)

        const vehicleVdpUrl = t(`vehicle.detailsWithQuote.${categoryName}`, {
            year,
            make: make?.slug,
            model: model?.slug,
            vehicleId,
            quoteId,
        })
        WindowProxy.location.href = vehicleVdpUrl
    }

    const dummyStickyCtas = [
        {
            buttonLabel: t('cta.addToGarage'),
            version: 'primary',
            slug: 'add_to_garage',
            id: 'addTogarage',
        },
        {
            buttonLabel: t('cta.reserveNow'),
            version: 'primary',
            slug: 'checkout',
            id: 'reserveNow',
        },
    ]

    const ctas = newVehicle ? newCtas : usedCtas

    return (
        <div className={`${baseClass}`} style={{ overflow: 'hidden' }}>
            <StickyBar wrapperClass={`${baseClass}__stickyBar ${baseClass}__wrapper`} targetClass={`.${baseClass}`} offset={200}>
                <div className={`${baseClass}__stickyBarInfosLeft ${device !== 'desktop' ? '-responsive' : ''}`}>
                    <VehicleName year={year} makeName={make?.name} modelName={model?.name} trimName={trim?.name} isFullName />

                    <div className={`${baseClass}__stickyBarVehicleRebates`}>
                        <VehicleRebatesList
                            taggingConfigurations={taggingConfigurations}
                            bestIncentives={paymentOptions?.bestIncentives}
                            lang={lang}
                            extraClasses={`${baseClass}__vehicleRebate`}
                            priceRounding={priceRounding}
                        />
                    </div>
                    {device === 'desktop' && (
                        <VehicleSpecsExcerpt
                            className={`${baseClass}__stickyBarVehicleSpecs`}
                            exteriorColor={vehicle?.exteriorColor}
                            interiorColor={vehicle?.interiorColor}
                            fuelName={fuel?.name}
                            fuelType={fuel?.slug}
                            odometer={odometer}
                            isNewVehicle={newVehicle}
                            lang={lang}
                            layout='-line'
                            batteryCapacity={batteryCapacity}
                            batteryRange={batteryRange}
                            driveTrainDetails={driveTrainDetails}
                        />
                    )}
                </div>
                {device === 'desktop' && (
                    <div className={`${baseClass}__stickyBarInfosRight`}>
                        <StickyPaymentOptions
                            newVehicle={newVehicle}
                            paymentOptions={paymentOptions}
                            isVDPView
                            purchaseMethod={showroomPaymentOptions?.purchaseMethod}
                            showroomQuote={showroomPaymentOptions}
                            lang={lang}
                            handleCalculatorCta={handleCalculatorCta}
                            isCalculatorModalOpen={!!isCalculatorModalOpen}
                            vehicleId={vehicleId}
                            showRetailsTotal={false}
                            priceRounding={priceRounding}
                            displayTotalCreditCost={displayTotalCreditCost}
                        />

                        <div className={`${baseClass}__stickyBarVehicleCtas`}>
                            <VehicleCtas
                                vehicle={vehicle}
                                showroomQuote={showroomPaymentOptions}
                                category='inventory'
                                paymentOptions={paymentOptions}
                                dealerInfos={dealerInfos}
                                ctas={dummyStickyCtas}
                            />
                        </div>
                    </div>
                )}
            </StickyBar>

            <div className={`${baseClass}__wrapper`}>
                <div className={`${baseClass}__grid`}>
                    {device && tradeInAtTheTop && (
                        <div className={`${baseClass}__wrapperTrade360`}>
                            <Trade360Widget
                                extraClasses={`${baseClass}__trade360`}
                                widgetId={'tradeInTopOfThePage'}
                                tradeInWidget={tradeInAtTheTop}
                                elementNodeName={'data-trade-standalone'}
                                elementNodeValue={'tradeInAtTheTop'}
                            />
                        </div>
                    )}
                    <div className={`${baseClass}__navigation`}>
                        <VdpBreadcrumb
                            make={make?.name}
                            model={model?.name}
                            year={year}
                            trim={trim?.name}
                            hubCategory={newVehicle ? 'new' : 'used'} // Use vehicle status since we don't have Hub name for VDP
                            extraClasses={`${baseClass}__breadcrumb`}
                        />
                    </div>

                    <main className={`${baseClass}__main`}>
                        <VehicleRebatesList
                            taggingConfigurations={taggingConfigurations}
                            bestIncentives={paymentOptions?.bestIncentives}
                            lang={lang}
                            extraClasses={`${baseClass}__vehicleRebate`}
                            priceRounding={priceRounding}
                        />

                        {device && device !== 'desktop' && (
                            <VehicleName
                                year={year}
                                makeName={make?.name}
                                modelName={model?.name}
                                trimName={trim?.name}
                                isFullName
                            />
                        )}
                        <div className={`${baseClass}__pictureGallery`}>
                            <div className={`${baseClass}__pictureGalleryOverlay`}>
                                <RecentViews
                                    recentViewCount={pageViewSummary?.recentViewCount}
                                    recentViewConfiguration={recentViewData}
                                />
                                <CompareButton
                                    vehicleId={vehicleId}
                                    labelUnchecked={t('vehicle.details.compare.add')}
                                    labelChecked={t('vehicle.details.compare.remove')}
                                    categoryName={'new'}
                                    pictoName={'add-car'}
                                />
                            </div>

                            <VehicleSlider
                                multimedia={multimedia}
                                newVehicle={newVehicle}
                                extraClasses={`${baseClass}__vehicleSlider`}
                            />
                        </div>
                        {device && tradeInBelowPicture && (
                            <div className={`${baseClass}__wrapperTrade360 -afterPicture`}>
                                <Trade360Widget
                                    extraClasses={`${baseClass}__trade360`}
                                    widgetId={'tradeInBelowPicture'}
                                    tradeInWidget={tradeInBelowPicture}
                                    elementNodeName={'data-trade-standalone'}
                                    elementNodeValue={'tradeInBelowPicture'}
                                />
                            </div>
                        )}

                        {device && device !== 'desktop' && (
                            <div className='vehicleInfo'>
                                <div className='vehicleInfo__container'>
                                    <VehicleTagline tagline={tagline} extraClasses={`${baseClass}__tagline`} />

                                    <div className='vehicleTabs'>
                                        <PaymentOptions
                                            newVehicle={newVehicle}
                                            paymentOptions={paymentOptions}
                                            isVDPView
                                            purchaseMethod={showroomPaymentOptions?.purchaseMethod}
                                            showroomQuote={showroomPaymentOptions}
                                            lang={lang}
                                            handleCalculatorCta={handleCalculatorCta}
                                            isCalculatorModalOpen={!!isCalculatorModalOpen}
                                            vehicleId={vehicleId}
                                            showRetailsTotal={false}
                                            forceUpdatePurchaseMethod={forceUpdatePurchaseMethod}
                                            priceRounding={priceRounding}
                                            displayTotalCreditCost={displayTotalCreditCost}
                                        />
                                        <section className={`${baseClass}__vehicleSpecsExcerptMobile`}>
                                            <Heading Tag='h2' variant='heading3'>
                                                {t('vehicle.specifications')}
                                            </Heading>

                                            <VehicleSpecsExcerpt
                                                exteriorColor={vehicle?.exteriorColor}
                                                interiorColor={vehicle?.interiorColor}
                                                fuelName={fuel?.name}
                                                fuelType={fuel?.slug}
                                                odometer={odometer}
                                                isNewVehicle={newVehicle}
                                                lang={lang}
                                                layout='-line'
                                                batteryCapacity={batteryCapacity}
                                                batteryRange={batteryRange}
                                                driveTrainDetails={driveTrainDetails}
                                            />
                                        </section>

                                        {certifiedAboveCTA && (
                                            <VehicleCertified
                                                isCertified={certified}
                                                dealerMakes={orgUnitMakes}
                                                vehicleMakeSlug={make?.slug}
                                                lang={lang}
                                                imageSize='w300'
                                                extraClasses={`${baseClass}__vehicleCertified`}
                                            />
                                        )}

                                        {!newVehicle && historyAboveCTA && (
                                            <VehicleHistory
                                                vehicle={vehicle}
                                                domain={domain}
                                                pastOwner={pastOwner}
                                                severelyDamagedVehicle={severelyDamagedVehicle}
                                                repairHistory={repairHistory}
                                                futureReparations={futureReparations}
                                                previousUse={previousUse}
                                                headingTag='h4'
                                                extraClasses={`${baseClass}__vehicleHistory`}
                                            />
                                        )}

                                        <Heading
                                            extraClasses={`${baseClass}__ctaSteps__header`}
                                            Tag='h4'
                                            variant='heading3'
                                            subTitle={t('vehicle.cta.nextSteps.description')}
                                        >
                                            {t('vehicle.cta.nextSteps.title')}
                                        </Heading>

                                        <VehicleCtas
                                            display='full'
                                            calculatorWidget={calculatorWidget}
                                            vehicle={vehicle}
                                            showroomQuote={showroomPaymentOptions}
                                            category='inventory'
                                            paymentOptions={paymentOptions}
                                            dealerInfos={dealerInfos}
                                            ctas={ctas}
                                        />

                                        {certifiedBelowCTA && (
                                            <VehicleCertified
                                                isCertified={certified}
                                                dealerMakes={orgUnitMakes}
                                                vehicleMakeSlug={make?.slug}
                                                lang={lang}
                                                imageSize='w300'
                                                extraClasses={`${baseClass}__vehicleCertified`}
                                            />
                                        )}

                                        {!newVehicle && historyBelowCTA && (
                                            <VehicleHistory
                                                vehicle={vehicle}
                                                domain={domain}
                                                pastOwner={pastOwner}
                                                severelyDamagedVehicle={severelyDamagedVehicle}
                                                repairHistory={repairHistory}
                                                futureReparations={futureReparations}
                                                previousUse={previousUse}
                                                headingTag='h4'
                                                extraClasses={`${baseClass}__vehicleHistory`}
                                            />
                                        )}

                                        {isMultiDealerInventory && (
                                            <DealerName
                                                extraClasses={`${baseClass}__dealerName`}
                                                primaryOrganizationUnit={primaryOrganizationUnit}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        <PageSections />
                    </main>

                    {device === 'desktop' && (
                        <aside className={`${baseClass}__aside`}>
                            <section style={{ top: heightCalculator('data-header') }} data-s360-sticky>
                                <div className='vehicleInfo'>
                                    <div className='vehicleInfo__container'>
                                        <VehicleName
                                            year={year}
                                            makeName={make?.name}
                                            modelName={model?.name}
                                            trimName={trim?.name}
                                            isFullName
                                            extraClasses={`${baseClass}__vehicleName`}
                                        />

                                        <VehicleTagline tagline={tagline} extraClasses={`${baseClass}__tagline`} />

                                        <div className='vehicleTabs'>
                                            <PaymentOptions
                                                newVehicle={newVehicle}
                                                paymentOptions={paymentOptions}
                                                isDetailView
                                                purchaseMethod={showroomPaymentOptions?.purchaseMethod}
                                                showroomQuote={showroomPaymentOptions}
                                                isVDPView
                                                lang={lang}
                                                calculatorWidget={calculatorWidget}
                                                showRetailsTotal={false}
                                                handleCalculatorCta={handleCalculatorCta}
                                                isCalculatorModalOpen={!!isCalculatorModalOpen}
                                                vehicleId={vehicleId}
                                                forceUpdatePurchaseMethod={forceUpdatePurchaseMethod}
                                                priceRounding={priceRounding}
                                                displayTotalCreditCost={displayTotalCreditCost}
                                            />

                                            {certifiedAboveCTA && (
                                                <VehicleCertified
                                                    isCertified={certified}
                                                    dealerMakes={orgUnitMakes}
                                                    vehicleMakeSlug={make?.slug}
                                                    lang={lang}
                                                    imageSize='w300'
                                                    extraClasses={`${baseClass}__vehicleCertified`}
                                                />
                                            )}

                                            {!newVehicle && historyAboveCTA && (
                                                <VehicleHistory
                                                    vehicle={vehicle}
                                                    domain={domain}
                                                    pastOwner={pastOwner}
                                                    severelyDamagedVehicle={severelyDamagedVehicle}
                                                    repairHistory={repairHistory}
                                                    futureReparations={futureReparations}
                                                    previousUse={previousUse}
                                                    headingTag='h4'
                                                    extraClasses={`${baseClass}__vehicleHistory`}
                                                />
                                            )}

                                            <Heading
                                                extraClasses={`${baseClass}__ctaSteps__header`}
                                                Tag='h4'
                                                variant='heading3'
                                                subTitle={t('vehicle.cta.nextSteps.description')}
                                            >
                                                {t('vehicle.cta.nextSteps.title')}
                                            </Heading>

                                            <VehicleCtas
                                                display='full'
                                                calculatorWidget={calculatorWidget}
                                                vehicle={vehicle}
                                                showroomQuote={showroomPaymentOptions}
                                                category='inventory'
                                                paymentOptions={paymentOptions}
                                                dealerInfos={dealerInfos}
                                                ctas={ctas}
                                            />

                                            {certifiedBelowCTA && (
                                                <VehicleCertified
                                                    isCertified={certified}
                                                    dealerMakes={orgUnitMakes}
                                                    vehicleMakeSlug={make?.slug}
                                                    lang={lang}
                                                    imageSize='w300'
                                                    extraClasses={`${baseClass}__vehicleCertified`}
                                                />
                                            )}

                                            {!newVehicle && historyBelowCTA && (
                                                <VehicleHistory
                                                    vehicle={vehicle}
                                                    domain={domain}
                                                    pastOwner={pastOwner}
                                                    severelyDamagedVehicle={severelyDamagedVehicle}
                                                    repairHistory={repairHistory}
                                                    futureReparations={futureReparations}
                                                    previousUse={previousUse}
                                                    headingTag='h4'
                                                    extraClasses={`${baseClass}__vehicleHistory`}
                                                />
                                            )}

                                            {isMultiDealerInventory && (
                                                <DealerName
                                                    extraClasses={`${baseClass}__dealerName`}
                                                    primaryOrganizationUnit={primaryOrganizationUnit}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {tradeInBelowCTA && (
                                <Trade360Widget
                                    extraClasses={`${baseClass}__trade360`}
                                    widgetId={'tradeInBelowCTA'}
                                    tradeInWidget={tradeInBelowCTA}
                                    elementNodeName={'data-trade-standalone'}
                                    elementNodeValue={'tradeInBelowCTA'}
                                />
                            )}

                            {newVehicle && (vehiclePromo?.promotions.length > 0 || articleVehicle.length > 0) && (
                                <section className={`${baseClass}__promotionArticle`}>
                                    {vehiclePromo?.promotions.length > 0 && (
                                        <PromotionCard
                                            extraClasses={`${baseClass}__promotion`}
                                            promotion={vehiclePromo.promotions[0]}
                                        />
                                    )}
                                    {articleVehicle.length > 0 && (
                                        <ArticleCard extraClasses={`${baseClass}__article`} article={articleVehicle[0]} />
                                    )}
                                </section>
                            )}
                        </aside>
                    )}
                    <div className={`${baseClass}__wrapperSimilar`}>
                        {displayCarouselSimilar && (
                            <div className={`${baseClass}__similar`}>
                                <CarouselSimilar
                                    vehicleList={similarVehicles}
                                    hubName={hubName}
                                    pictureConfiguration={pictureConfiguration}
                                    onSimilarModelTileClick={(vehicleId, categoryName) =>
                                        handleSimilarModelTileClick(vehicleId, categoryName)
                                    }
                                    XMSConfig={XMSConfig}
                                    dealerInfos={dealerInfos}
                                    saveQuote={saveQuote}
                                />
                            </div>
                        )}
                    </div>
                    {device && tradeInAtTheBottom && (
                        <div className={`${baseClass}__wrapperTrade360 -bottom`}>
                            <Trade360Widget
                                extraClasses={`${baseClass}__trade360`}
                                widgetId={'tradeInAtTheBottom'}
                                tradeInWidget={tradeInAtTheBottom}
                                elementNodeName={'data-trade-standalone'}
                                elementNodeValue={'tradeInAtTheBottom'}
                            />
                        </div>
                    )}
                </div>
            </div>

            {device && device !== 'desktop' && (
                <HorizontalDrawer
                    SmallContent={() => (
                        <>
                            <StickyPaymentOptions
                                newVehicle={newVehicle}
                                paymentOptions={paymentOptions}
                                isVDPView
                                purchaseMethod={showroomPaymentOptions?.purchaseMethod}
                                showroomQuote={showroomPaymentOptions}
                                lang={lang}
                                calculatorWidget={calculatorWidget}
                                showRetailsTotal={false}
                                extraClasses={`${baseClass}__mobileFooterPaymentOptions`}
                                priceRounding={priceRounding}
                                displayTotalCreditCost={displayTotalCreditCost}
                            />

                            <VehicleCtas
                                display='half'
                                vehicle={vehicle}
                                showroomQuote={showroomPaymentOptions}
                                category='inventory'
                                paymentOptions={paymentOptions}
                                dealerInfos={dealerInfos}
                                ctas={dummyStickyCtas}
                                extraClasses={`${baseClass}__mobileFooterCtas`}
                            />
                        </>
                    )}
                    handleCalculatorCta={handleCalculatorCta} // TODO: Remove handleCalculatorCta props to open calculator once we have Drawer content
                >
                    <p>Big Content (future payment calculator)</p>
                </HorizontalDrawer>
            )}
            <div className={`${baseClass}__calculator`} data-standalone='paymentCalculator'></div>
        </div>
    )
}

export default VdpAlpha
